import types from './types';
var mutations = {
  [types.mutations.LOGIN_FORM_SET_FIELD]: (state, payload) => {
    state.loginForm[payload.field] = payload.value;
  },
  [types.mutations.SET_LOADING]: (state, loading) => {
    state.loading = loading;
  },
  [types.mutations.SET_ERROR]: (state, error) => {
    state.loading = undefined;
    state.error = error;
  },

  [types.mutations.SET_CLUBS]: (state, payload) => {
    const { clubs, accessToken, firstLogin } = payload;
    state.clubForm.clubs = [...clubs];
    state.accessToken = accessToken;
    state.firstLogin = firstLogin;
    let selectedClub;
    if (localStorage && localStorage.getItem('selectedClub') && payload.clubs.find((x) => x.id === String(localStorage.getItem('selectedClub')))) {
      selectedClub = localStorage.getItem('selectedClub');
    } else {
      selectedClub = payload.clubs[0].id;
      if (localStorage) {
        localStorage.setItem('selectedClub', selectedClub);
      }
    }
    state.clubForm.selectedClub = selectedClub;
    state.loading = undefined;
  },
  [types.mutations.SELECT_CLUB]: (state, payload) => {
    if (localStorage) {
      const selectedClub = localStorage.getItem('selectedClub');
      if (selectedClub && selectedClub !== payload) {
        const goMenu = localStorage.getItem('goMenu');
        localStorage.clear();
        localStorage.setItem('goMenu', goMenu);
      }
      state.clubChanged = true;
      localStorage.setItem('selectedClub', payload);
    }
    state.clubForm.selectedClub = payload;
  },
  [types.mutations.RESET_LOGIN]: (state) => {
    state.clubChanged = false;
    state.loading = undefined;
    state.error = undefined;
    state.loginForm = {
      golfId: '',
      password: '',
      submitted: false,
      newPassword: undefined,
    };
    state.clubForm = {
      clubs: [],
      selectedClub: '',
    };
    state.passwordForm = {
      golfId: '',
      email: '',
      isGolfIdValid: true,
      isEmailValid: true,
      isActive: false,
      submitted: false,
      passwordSent: false,
    };
    state.accessToken = undefined;
    state.firstLogin = false;
    state.frejaLogin = false;
    state.frejaAuthRef = undefined;
    state.frejaStatus = undefined;
    state.frejaToken = undefined;
  },
  [types.mutations.PASSWORD_FORM_SET_FIELD]: (state, payload) => {
    state.passwordForm[payload.field] = payload.value;
  },
  [types.mutations.SET_FIELD]: (state, fieldValue) => {
    state[fieldValue.field] = fieldValue.value;
  },
};

export default mutations;
