<template>
  <div class="login-form-view">
    <input type="tel" style="opacity: 0; position: fixed; top: -100%" autocomplete="off" />
    <input type="password" style="opacity: 0; position: fixed; top: -100%" value="123123" autocomplete="new-password" />
    <div class="ace-column">
      <TextInputAtom
        ref="golfId"
        label="Golf-ID"
        type="tel"
        mask="######-###"
        placeholder="______-___"
        :value="loginForm.golfId"
        :error="validation['golfId']"
        @input="onGolfIdInput"
      />
      <TextInputAtom
        ref="passwordInput"
        label="Lösenord"
        type="password"
        :error="validation['password']"
        :value="loginForm.password"
        @input="onPasswordInput"
      />
      <div class="login-form-view__is-starter" @click="onStarterToggle">
        <CheckAtom :value="isStarter" option="Jag loggar in som starter" @input="onStarterToggle" />
      </div>
      <ValidationAtom v-if="errorMessage" :value="errorMessage" />
      <div class="ace-row login-form-view__action-panel">
        <ButtonAtom label="Logga in" @click="onLoginClick" />
        <ButtonAtom type="link" label="Glömt lösenord?" @click="forgotPassword" />
      </div>
      <div class="ace-column">
        <p class="ace-center">eller</p>
        <div class="ace-center">
          <ButtonAtom type="link" label="Logga in med Freja+" @click="frejaInitAuthentication" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import types from '../../store/types';
import { ButtonAtom, TextInputAtom, ValidationAtom, CheckAtom } from '@sgf/go-ace-ui';

export default {
  name: 'LoginFormView',
  components: {
    ButtonAtom,
    TextInputAtom,
    ValidationAtom,
    CheckAtom,
  },
  computed: {
    ...mapState(['error', 'isStarter']),
    ...mapGetters({
      loginForm: types.getters.GET_LOGIN_FORM,
      validation: types.getters.LOGIN_VALIDATION,
    }),
    errorMessage() {
      return this.error == 'Sgf.Unauthorized' ? 'Felaktigt användarnamn eller lösenord' : this.error;
    },
  },
  methods: {
    ...mapActions({
      loginFormSetField: types.actions.LOGIN_FORM_SET_FIELD,
      loginFormSubmitted: types.actions.LOGIN_FORM_SUBMITTED,
      passwordFormSetField: types.actions.PASSWORD_FORM_SET_FIELD,
      frejaInitAuthentication: types.actions.FREJA_INIT_AUTHENTICATION,
      setField: types.actions.SET_FIELD,
    }),
    onGolfIdInput(val) {
      if (val.length === 10) {
        const passwordInput = this.$refs.passwordInput;
        const inputElement = passwordInput.$el.querySelector('input');
        if (inputElement) {
          inputElement.focus();
        }
      }
      this.loginFormSetField({
        field: 'golfId',
        value: val,
      });
    },
    onPasswordInput(val) {
      this.loginFormSetField({
        field: 'password',
        value: val,
      });
    },
    onStarterToggle() {
      this.setField({
        field: 'isStarter',
        value: !this.isStarter,
      });
    },
    onLoginClick(e) {
      e.preventDefault();
      this.loginFormSubmitted();
    },
    forgotPassword() {
      this.passwordFormSetField({
        field: 'isActive',
        value: true,
      });
    },
    handleEnter(e) {
      if (e.key === 'Enter') {
        this.loginFormSubmitted();
      }
    },
  },
  mounted() {
    if (this.$refs.golfId) {
      this.$refs.golfId.$refs.input.focus();
    }
    window.addEventListener('keyup', this.handleEnter);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.handleEnter);
    this.setField({ field: 'error', value: undefined });
  },
};
</script>

<style scoped>
.login-form-view__action-panel {
  flex-direction: row-reverse;
}
.login-form-view :deep(input::-ms-reveal),
.login-form-view :deep(input::-webkit-reveal-password-button),
.login-form-view :deep(input::-webkit-credentials-auto-fill-button) {
  display: none;
}
.login-form-view__is-starter {
  background: var(--ace-c-primary-blue-light);
  padding: 16px;
  border-radius: 6px;
  border: 1px solid var(--ace-c-primary-blue);
}
</style>
