import { TypesBase } from '@sgf/go-utilities';

class LoginTypes extends TypesBase {
  constructor() {
    super('login');
  }

  getters = {
    GET_LOGIN_FORM: 'getLoginForm',
    GET_LOADER: 'getLoader',
    GET_CLUB_FORM: 'getClubForm',
    GET_CLUB_NAME: 'getClubName',
    GET_PASSWORD_FORM: 'getPasswordForm',
    GET_CLUBS: 'getClubs',
    GET_MESSAGE: 'getMessage',
    LOGIN_VALIDATION: 'loginValidation',
    RESET_PASSWORD_VALIDATION: 'resetPasswordValidation',
  };

  mutations = {
    LOGIN_FORM_SET_FIELD: 'loginFormSetField',
    SET_ERROR: 'setError',
    SET_LOADING: 'setLoading',
    SET_LOGIN_FORM_AS_VALID: 'setLoginFormAsValid',
    SET_MESSAGE: 'setMessage',
    RESET_MESSAGE: 'resetMessage',
    SET_CLUBS: 'setClubs',
    SELECT_CLUB: 'selectClub',
    RESET_LOGIN: 'resetLogin',
    PASSWORD_FORM_SET_FIELD: 'passwordFormSetField',
    SET_FIELD: 'setField',
  };

  actions = {
    LOGIN_FORM_SET_FIELD: 'loginFormSetField',
    LOGIN_FORM_SUBMITTED: 'loginFormSubmitted',
    LOGIN: 'login',
    SELECT_CLUB: 'selectClub',
    RESET_LOGIN: 'resetLogin',
    SET_LOADING: 'setLoading',
    RESET_MESSAGE: 'resetMessage',
    PASSWORD_FORM_SET_FIELD: 'passwordFormSetField',
    SEND_NEW_PASSWORD: 'sendNewPassword',
    SET_FIELD: 'setField',
    FREJA_INIT_AUTHENTICATION: 'frejaInitAuthentication',
    FREJA_AUTHENTICATION_RESULT: 'frejaAuthenticationResult',
  };
}

const types = new LoginTypes();

export default types;
